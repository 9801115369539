.TitlePage > .title > .back-icon {
  height: 38px;
  background-image: url(../../assets/temp/back2.svg);
  background-repeat: no-repeat;
  background-size: 20px;
  background-position: left center;
  padding: 0 0 0 30px;
}

.TitlePage > .title .subtitle {
  position: absolute;
  font-size: 13px;
  color: #999;
}
