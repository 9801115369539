#header-account #account > #logout {
  background: url(../../assets/temp/logout.svg) no-repeat;
  background-position: 58px center;
  background-size: 16px;
  padding: 0 31px 0 10px;
  line-height: 30px;
  border: 2px solid #e7e9ed;
  border-radius: 3px;
  background-color: #fefefe;
  cursor: pointer;
  display: inline-block;
}
