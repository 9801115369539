#header-page > .title.back {
  background-image: url(../../assets/temp/back2.svg);
  background-repeat: no-repeat;
  background-size: 20px;
  background-position: left center;
  padding: 0 0 0 30px;
}

#header-page > .title > .subtitle {
  position: absolute;
  font-size: 13px;
  color: #999;
}

#header-page .tabs > a.tab,
#header-page .tabs > .tab {
  padding: 0 5px 10px 5px;
  font-size: 16px;
}

#header-page .tabs > .tab.selected {
  border-bottom: 3px solid #213B54;
  font-weight: 700;
}
