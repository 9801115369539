// ---- nv style custom start
.nv-container {
  margin: 0 80px 0 140px;
}
.nv-text-ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  max-width: 100%;
  word-break: break-all;
}

.nv-break-word {
  word-break: break-word;
}
// ---- nv style custom end

#main h2.title {
  font-weight: 600;
  font-size: 22px;
  margin: 20px 0 0 0;
  padding: 10px 0;
  position: relative;
}

#main h2.title.fields {
  margin: 20px 0;
}

#main h2.title > .title-button {
  position: absolute;
  display: block;
  right: 0;
  top: 5px;
  font-size: 14px;
  padding: 8px 16px;
  color: #fff;
  background: #f6b26b;
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-weight: 500;
  border: 0;
  border-radius: 4px;
  cursor: pointer;
}

#main hr {
  margin: 0;
  padding: 0;
  /* border-top: 1px solid #2c3f54; */
  border: 0;
}

#main .table {
  display: block;
}

#main .table a.row,
#main .table .row {
  overflow: auto;
  padding: 8px 10px;
  border-bottom: 1px solid #e9ebef;
  display: block;
  color: #333;
}

#main .table a.row:hover {
  background-color: #fcfdff;
}

#main .table .row.header {
  margin: 10px 0 0 0;
  padding: 12px 10px;
  border-top: 1px solid #e9ebef;
}

#main .table .row.table-pager {
  margin: 10px 0 0 0;
  padding: 12px 10px;
  border-top: 1px solid #e9ebef;
}

#main .stats .table .row:first-child {
  border-top: 1px solid #e9ebef;
}

#main .stats .table .row {
  padding: 0;
}

#main .stats .table .column {
  padding: 8px 10px;
}

#main .stats .table .header {
  font-weight: 500;
}

#main .table .row.table-pager,
#main .table .header {
  font-weight: 600;
  background: #f8f9fb;
  color: #666;
}

#main .table .row.table-pager {
  font-weight: 400;
  margin: 0;
  position: relative;
}

#main .table .column {
  float: left;
}

#main .table .header .column.rank {
  color: #f6b26b;
}

#main .table .column.rank {
  width: 5%;
}

#main .table .header .column.auto {
  color: #666;
  text-align: left;
  padding: 0;
}

#main .table .column.auto {
  color: #f6b26b;
  width: 5%;
  padding: 0 0 0 10px;
  box-sizing: border-box;
}

#main .table .column.note {
  width: 60%;
}

#main .table .column.name {
  width: 40%;
}

#main .table .column.username::before {
  content: '\200B';
}

#main .table .column.username {
  width: 20%;
}

#main .table .column.last-login {
  width: 30%;
  text-align: right;
}

#main .table .column.completed-questions {
  width: 30%;
  color: #f6b26b;
  text-align: right;
  padding-right: 70px;
  box-sizing: border-box;
}

#main .table .column.user {
  width: 20%;
  text-align: right;
}

#main .table .column.employees {
  width: 25%;
  text-align: right;
}

#main .table .column.stat {
  width: 50%;
  box-sizing: content-box;
}

#main .table .column.employee-name {
  width: 78%;
}

#main .table .header .column.admin {
  color: #666;
  text-align: left;
  padding: 0;
}

#main .table .column.admin {
  color: #f6b26b;
  width: 7%;
  padding: 0 0 0 18px;
  box-sizing: border-box;
}

#main .table .column.created-employee,
#main .table .column.created-note {
  text-align: right;
  width: 15%;
  float: right;
}

#main .table .column.created {
  text-align: right;
  width: 30%;
}

#main .table .column.user-created {
  text-align: right;
  width: 20%;
}

#main #search {
  margin: 20px 0 0 0;
  position: relative;
}

#main #search input[type='textbox'] {
  padding: 8px 12px 8px 40px;
  border-radius: 5px;
  border: 2px solid #e9ebef;
  width: 100%;
  position: relative;
  z-index: 5;
  font-family: 'Plus Jakarta Sans', sans-serif;
}

#main #search .icon {
  position: absolute;
  z-index: 10;
  height: 18px;
  width: 18px;
  background-image: url(assets/temp/search.svg);
  top: 9px;
  left: 14px;
}

#main .section.stats {
  width: 50%;
}

#main .form .label {
  margin: 10px 0 0 0;
  padding: 0 0 8px 0;
  color: #999;
  font-weight: 500;
  font-size: 15px;
}

textarea,
input[type='textbox'] {
  padding: 10px 20px;
  border: 2px solid #e9ebef;
  border-radius: 4px;
  font-family: 'Plus Jakarta Sans', sans-serif;
  color: #666;
  width: 100%;
}

#main .form textarea {
  height: 200px;
}

input[type='submit'] {
  padding: 10px 20px;
  color: #fff;
  background: #f6b26b;
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-weight: 600;
  border: 0;
  border-radius: 4px;
  cursor: pointer;
}

input[type='submit'].cursor-not-allowed {
  cursor: not-allowed;
}

#main .form input[type='submit'] {
  margin: 30px 0 0 0;
}

#main .two-column {
  overflow: auto;
}

#main .float {
  float: left;
  width: 50%;
}

#main .float.large-left {
  width: 70%;
}

#main .float.small-left {
  width: 30%;
  box-sizing: border-box;
}

#main .float.small-right {
  width: 30%;
  padding: 0 0 0 40px;
  box-sizing: border-box;
}

#main .users .float.small-left {
  width: 33.3%;
  box-sizing: border-box;
}

#main .users .float.small-right {
  width: 33.3%;
  padding: 0 0 0 40px;
  box-sizing: border-box;
}

#main .float {
  width: 50%;
  margin: 20px 0 0 0;
  box-sizing: border-box;
}

#main .float.right {
  float: right;
  padding: 0 0 0 50px;
}

#main .float.left {
  float: left;
  padding: 0 50px 0 0;
  border-right: 2px solid #e9ebef;
}

#main .float.left.mb-6 {
  margin-bottom: 24px;
}

#main .float.left {
  padding: 0 40px 0 0;
  box-sizing: border-box;
}

#main .form .float.right {
  padding: 0 0 0 40px;
  box-sizing: border-box;
}

#main .form .subscription-option {
  padding: 5px 0;
}

#main .form .subscription-option input[type='radio'] {
  margin: 3px 5px 0 5px;
}

#main .form select {
  padding: 9px 20px;
  border: 2px solid #e9ebef;
  border-radius: 4px;
  font-family: 'Plus Jakarta Sans', sans-serif;
  color: #666;
  width: 100%;
}

#main .form .upload-logo {
  height: 150px;
  width: 200px;
  text-align: center;
  line-height: 150px;
  background: #fafafa;
  border: 2px solid #e9ebef;
  border-radius: 4px;
  color: #999;
  cursor: pointer;
  margin: 3px 0 0 0;
}

#main .tabs {
  overflow: auto;
}

#main .tabs > a.tab,
#main .tabs > .tab {
  float: left;
  margin: 0 36px 0 0;
  padding: 20px 5px 20px 5px;
  cursor: pointer;
  color: #333;
  text-decoration: none;
  font-size: 15px;
}

#main .tabs > .tab.selected {
  border-bottom: 2px solid #213B54;
  font-weight: 500;
}

#main .filter {
  padding: 18px 0;
  border-top: 1px solid #e9ebef;
  color: #666;
  font-size: 15px;
  overflow: auto;
}

#main .filter.no-padding {
  padding: 0;
}

#main .filter label {
  padding: 0 0 0 5px;
}

#main .filter .info {
  float: right;
  color: #999;
  font-weight: 500;
}

#main .section.employees,
#main .section.notes {
  padding: 30px 0 0 0;
}

#main .section.employees .table .row.header,
#main .section.notes .table .row.header {
  margin: 0;
}

#main .pager {
  text-align: center;
  padding: 20px 0;
  font-size: 14px;
}

#main .pager .arrow {
  background-repeat: no-repeat;
  height: 22px;
  width: 22px;
  background-size: contain;
  display: inline-block;
  position: relative;
  top: 5px;
  margin: 0 6px;
}

#main .pager .left {
  background-image: url(assets/temp/back3.svg);
}

#main .pager .right {
  background-image: url(assets/temp/forward2.svg);
}

#main .pager .current {
  display: inline-block;
}

#main .pager .current input[type='textbox'] {
  width: 34px;
  padding: 6px 12px;
  margin: 0 3px 0 0;
  border: 2px solid #e9ebef;
  border-radius: 4px;
  font-family: 'Plus Jakarta Sans', sans-serif;
  color: #666;
  display: inline-block;
}

#main #add-note textarea {
  height: 100px;
}

#main .table .row.table-pager .current {
  text-align: center;
}

#main .table .row.table-pager .previous {
  position: absolute;
  left: 5px;
  top: 12px;
}

#main .all-events {
  color: #666;
  margin: 30px 0 0 0px;
  font-size: 16px;
  text-decoration: none;
}

#main .all-events a {
  color: #666;
  text-decoration: none;
}

#main .category {
  padding: 0;
  color: #333;
  font-weight: 500;
  font-size: 18px;
}

#main .answer {
  font-size: 16px;
  color: #666;
  padding: 7px 0 15px 0;
}

#main .answer.none {
  color: #aaa;
}

// custom from design folder
.nv-pagination {
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  color: #666666;
  background: #f8f9fb;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

input.slugBox {
  margin: 0 0 0 2px;
  padding-left: 5px;
}

.intercom-container img {
  width: 100%;
  object-fit: fill;
}
