@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  background: #fff;
  color: #333;
  padding: 0;
  font-family: 'Plus Jakarta Sans', sans-serif;
}
.tooltiptext::after {
  content: '';
  position: absolute;
  left: 12px;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid #000000b2;
  rotate: 180deg;
}

.tooltiptextV2::after {
  content: '';
  position: absolute;
  left: 80px;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid #000000b2;
  rotate: 180deg;
}

.tooltip-text-with-sub-title::after {
  content: '';
  position: absolute;
  left: 80px;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid #000000b2;
  rotate: 180deg;
}

.tooltip-text-with-sub-title-latest::after {
  content: '';
  position: absolute;
  left: 150px;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid #000000b2;
  rotate: 180deg;
}

.tooltip-text-with-sub-title-first::after {
  content: '';
  position: absolute;
  left: 40px;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid #000000b2;
  rotate: 180deg;
}

.recharts-active-dot {
  cursor: pointer;
}

.custom-editor .DraftEditor-root {
  height: 160px;
  overflow-y: 'hidden';
}




