#login {
  margin: 200px auto;
  border-radius: 5px;
  box-shadow: 0 0 20px 5px rgb(0 0 0 / 20%);
}

#login .title {
  background-image: url(../../assets/temp/logo_compact.svg);
  background-position: 55px center;
}
